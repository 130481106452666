<template>
  <Header :title="t(`settings.title`)" icon="fa-cogs" />
  <Content class="overflow-y-scroll items-center flex-col">
    <div class="pt-3 pb-6">
      <ul class="flex h-10 ">
        <router-link
          tag="li"
          class="flex items-center py-3 px-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green rounded-l-full"
          exact-active-class="from-yellow-dark to-yellow"
          :to="{ name: 'SettingsGeneral' }"
        >
          <p>{{ t(`settings.general`) }}</p>
        </router-link>
        <router-link
          tag="li"
          class="flex items-center py-3 px-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green"
          exact-active-class="from-yellow-dark to-yellow"
          :to="{ name: 'SettingsEmployees' }"
        >
          <p>{{ t(`settings.employees`) }}</p>
        </router-link>
        <router-link
          tag="li"
          class="flex items-center py-3 px-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green rounded-r-full"
          exact-active-class="from-yellow-dark to-yellow"
          :to="{ name: 'SettingsUnits' }"
        >
          <p>{{ t(`settings.units`) }}</p>
        </router-link>
      </ul>
    </div>

    <router-view />
  </Content>
</template>

<script>
import Header from "@/components/Header";
import Content from "@/components/Content";

import { useI18n } from "vue-i18n";

export default {
  name: "TimeRegistrationSettings",
  components: { Header, Content },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {},
        dk: {}
      }
    });
    return { t };
  }
};
</script>
